<template>
  <section id="help" class="section section-lg bg-get-start"
    :style="{ 'background': 'url(' + require('@/assets/images/bg/bg11.jpg') + ')', 'background-size': 'cover', 'background-position': 'center' }">
    <div class="bg-overlay-black"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 text-center">
          <p class="section-title text-white">客户服务</p>
          <div class="section-title-border margin-t-20"></div>
          <p
            class="section-subtitle font-secondary text-white text-center padding-t-30 line-height-32 padding-bottom-20">
            如您有任何疑问或其它需求，请通过客服电话或电子邮件或在线留言方式与我们沟通，我们会竭诚为您服务。<br />对于一些常见问题您还可以通过点击下方按钮获取解答，感谢您的支持！</p>
          <el-button round @click="openfaq()" type="primary">常见问题解答</el-button>
        </div>
      </div>
    </div>
    <el-drawer title="常见问题" :visible.sync="drawer" direction="rtl" :before-close="handleClose" size="440px">
      <div>
        <div v-if="isempty" class="container">
          <el-empty description="没有检索到数据"></el-empty>
        </div>
        <div v-else>
          <div class="text-center">
            <img src="@/assets/images/bg/es2.png" class="img-fluid rounded" />
          </div>
          <div class="row margin-t-30 container">
            <div class="col-lg-12" v-for="(item, index) in faqs" :key="index">
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item :title="item.question" :name=index>
                  <div class="myp padding-top-15 line-height-32" v-html="item.answer"></div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </section>
</template>
<script>
export default {
  name: 'Faq',
  data() {
    return {
      drawer: false,
      activeName: 100,
    }
  },
  props: {
    faqs: {
      type: Array,
      default: function () { return []; }
    },
    isempty: {
      type: Boolean,
      default: function () { return false; }
    }
  },
  methods: {
    openfaq() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    }
  }
}
</script>